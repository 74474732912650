<template>
  <dashboard-card class="relative flex flex-col justify-between items-stretch">
    <trial-alert
      v-show="showTrialBadge"
      :website-id="website.id"
      :created-at="createdAt"
      @upgrade="showPaymentModal"
    />
    <div class="flex justify-between items-center p-3">
      <div
        v-if="showMenu"
        v-click-outside="closeOptionsMenu"
        class="absolute focus:outline-none site-options-menu-wrapper"
      >
        <site-options-menu
          :site="website"
          :user="user"
          :sites="websites"
          :find-my-websites="findMyWebsites"
        />
      </div>
      <div class="d-flex">
        <span
          v-if="!website.isPro && showTrialBadge"
          class="text-gray-600 font-bold py-2 px-4 text-xs inline-flex items-center"
        >
          {{ $t("website_card.proTrial") }}
        </span>
        <button
          v-show="!website.isPro && !showTrialBadge"
          class="buttonNone text-gray-600 font-bold py-2 px-4 text-xs inline-flex items-center bg-gray-100 rounded-full"
        >
          <!-- {{ $t("builder.upgrade_to_pro") }} -->
          {{ $t("website_card.free") }}
        </button>
        <span
          v-show="website.isPro || isAgency"
          class="text-white backgroundStyle font-bold py-2 px-4 text-xs inline-flex items-center bg-blue-500 rounded-full ml-3"
        >
          <img class="imgSmall" src="@/assets/icons/premium.svg" />
          {{ $t("website_card.pro") }}
        </span>
      </div>
      <button
        class="overItem focus:outline-none text-gray-600 hover:text-gray-800 font-bold py-2 px-4 text-lg inline-flex items-center"
        @click="toggleOptionsMenu"
      >
        <b-icon name="ellipsis-h" />
      </button>
    </div>
    <div class="px-6 py-2 h-full flex flex-col items-center justify-center">
      <div>
        <div class="websitePublish">
          <span class="titleText marginright5 is-block has-text-weight-bold">
            {{ website.name }}
          </span>
          <span v-if="!website.published">
            <b-tooltip
              size="is-small"
              multilined
              :label="$t('site_options_menu.tooltipPublish')"
            >
              <!-- position="is-left" -->
              <img class="imgSmall" src="@/assets/icons/eye_hide.svg" />
            </b-tooltip>
          </span>
        </div>
        <div>
          <a :href="`http://${domain}`" target="_blank" class="textLink">
            {{ domain }}
          </a>
          <b-icon class="iconBlue" name="external-link-alt" />
        </div>
        <div>
          <a
            v-if="usesCustomDomain"
            :href="`http://${subdomain}`"
            target="_blank"
            class="textLink"
          >
            {{ subdomain }}
          </a>
          <b-icon
            v-if="usesCustomDomain"
            class="iconBlue"
            name="external-link-alt"
          />
        </div>
      </div>
    </div>
    <div class="flex-col items-center marginBottom3">
      <span v-if="!website.isPro && showTrialBadge" class="paddingDown"> </span>
      <button v-if="website.published" class="buttonEditStyle">
        <a class="aTextEdit text-blue" @click.stop="editWebsite(website)">
          <b-icon name="edit" class="text-blue bIconEdit" />
          {{ $t("builder.edit") }}
        </a>
      </button>

      <span
        v-show="!website.isPro || isAgency"
        class="text-blue paddingUp paddingDown flex flex-col items-center justify-center"
      >
        <button class="buttonUpdatePro items-center">
          <img
            class="imgSmallUpdate"
            src="@/assets/icons/upgrade-rounded.svg"
          />
          <a class="textWhitePay" @click="showPaymentModal">
            {{ $t("builder.upgrade_to_pro") }}
          </a>
        </button>
      </span>
    </div>
  </dashboard-card>
</template>

<script>
import DashboardCard from "./DashboardCard";
import SiteOptionsMenu from "./SiteOptionsMenu";
import BIcon from "@/components/BIcon";
import TrialAlert from "@/components/TrialAlert";
import vClickOutside from "v-click-outside";
import differenceInDays from "date-fns/differenceInDays";

export default {
  name: "WebsiteCard",

  components: {
    BIcon,
    DashboardCard,
    SiteOptionsMenu,
    TrialAlert
  },

  directives: {
    clickOutside: vClickOutside.directive
  },

  props: {
    website: {
      type: Object,
      required: true
    },
    websites: {
      type: Array,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    findMyWebsites: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      showMenu: false
    };
  },

  computed: {
    isAgency() {
      return this.$store.getters["isAgency"];
    },
    usesCustomDomain() {
      const isSubdomain =
        this.domain.endsWith(".getbiew.com") ||
        this.domain.endsWith(".biew.site");
      return !isSubdomain;
    },
    domain() {
      return this.website?.domain?.toLowerCase() || "";
    },
    subdomain() {
      return (this.domain.endsWith(".getbiew.com")
        ? `${this.website.subdomain}.getbiew.com`
        : `${this.website.subdomain}.biew.site`
      ).toLowerCase();
    },
    firstWebsite() {
      return this.$store.state.firstWebsite;
    },
    isFirstWebsite() {
      return this.firstWebsite === this.website.id;
    },
    createdAt() {
      return new Date(this.website?.createdAt?.seconds * 1000);
    },
    daysDifference() {
      const currentDate = Date.now();
      const daysDifference = differenceInDays(currentDate, this.createdAt);
      return 7 - daysDifference;
    },
    showTrialBadge() {
      return false;
    }
  },

  methods: {
    toggleOptionsMenu() {
      this.showMenu = !this.showMenu;
    },
    closeOptionsMenu() {
      this.showMenu = false;
    },
    editWebsite(website) {
      this.$store.dispatch("builder/setEditWebsite", website);
    },
    openWebsite(url) {
      window.open(url);
    },
    showPaymentModal() {
      this.$emit("upgrade", this.website);
    }
  }
};
</script>

<style lang="scss" scoped>
.site-options-menu-wrapper {
  top: 40px;
  right: 25px;
  z-index: 1;
}
.imgSmall {
  width: 20px;
  height: 20px;
  max-width: none !important;
  display: inline;
}
.websitePublish {
  flex-direction: row;
  display: flex;
  justify-content: center;
}
.marginBottom3 {
  margin-bottom: 3%;
}
.marginright5 {
  margin-right: 5%;
}
.iconBlue {
  width: 14px;
  height: 14px;
  margin-left: 1%;
  margin-right: 1%;
  color: gray;
}
.titleText {
  font-size: 20px;
  font-family: roboto !important;
}

.textLink {
  font-size: 12px;
}
.textLink:hover {
  width: 300px;
  color: #1069ff;
  background-color: #dbe9ff;
  font-size: 14px;
}
.textLink:hover + .iconBlue {
  color: #1069ff;
}
.buttonNone {
  cursor: default;
}
.bIconEdit {
  color: #0a69ff;
}
.aTextEdit {
  color: #0a69ff;
  font-size: 16px;
}
.aTextEdit:hover {
  color: #0a69ff;
  font-size: 17.41px;
  background-color: #dbe9ff;
}
.buttonEditStyle {
  width: 112px;
  height: 34px;
  border-radius: 8px;
}
.buttonEditStyle:hover.hidden {
  pointer-events: none;
  cursor: default;
}
.buttonEditStyle:hover {
  width: 121.88px;
  height: 37px;
  background: #dbe9ff;
  .aTextEdit {
    color: #0a69ff;
    font-size: 17.41px;
    background-color: #dbe9ff;
  }
}
.buttonUpdatePro {
  background: #1e2936;
  width: 180px;
  height: 36px;
  border-radius: 8px;
  align-items: center;
  display: flex;
}
.textWhitePay {
  color: white;
}
.buttonUpdatePro:hover {
  width: 185.15px;
  height: 38.47px;
  background: #08587a;
}

.backgroundStyle {
  background-color: #08587a;
}

.imgSmallUpdate {
  padding-left: 5%;
  padding-right: 5%;
}
.paddingUp {
  padding-top: 10px;
}
.paddingDown {
  padding-bottom: 10px;
}
.widthMore {
  width: 105%;
}
.noHover {
  width: 112px;
  height: 34px;
  border-radius: 8px;
}
.noHover:hover {
  pointer-events: none;
  cursor: default;
}
</style>
