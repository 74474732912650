<template>
  <b-modal
    :active="show"
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-modal
    has-modal-card
    style="width: auto"
    @update:active="$emit('update:show', $event)"
  >
    .
    <div class="modal-card" style="width: auto">
      <div
        class="alignCenter flex flex-col rounded-lg bg-white p-6"
        style="min-width: 500px; min-height: 400px;"
      >
        <div v-if="qrCode" class="alignCenter flex flex-col mb-4 text-center">
          <p class="subTitle">Scan to visit your website</p>
          <img :src="qrCode" class="imgSize border rounded-lg" />
          <div class="text-center mt-2">
            <a
              download="qrcode.png"
              :href="qrCode"
              class="text-blue-500 hover:text-blue-600"
            >
              Download
            </a>
            <b-button
              icon-right="angle-right"
              size="is-large"
              type="is-dark"
              expanded
              :loading="publishing"
              @click="$emit('accept')"
            >
              Accept
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import * as Sentry from "@sentry/browser";
import QRCode from "qrcode";

export default {
  name: "QrModal",

  props: {
    show: {
      type: Boolean,
      default: false
    },
    websiteUrl: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      publishing: false,
      qrCode: ""
    };
  },

  mounted() {
    this.generateQR();
  },

  methods: {
    async generateQR() {
      try {
        const qrCode = await QRCode.toDataURL(this.websiteUrl, {
          margin: 2,
          width: 150
        });
        this.qrCode = qrCode;
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    async close() {
      try {
        this.$emit("close");
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  }
};
</script>
<style>
.subTitle {
  font-size: 22px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 0.25px;
}
.imgSize {
  width: 200px;
  height: 200px;
}
.alignCenter {
  align-items: center;
}
</style>
