<template>
  <div
    class="transition-shadow duration-300 w-full h-64 text-center rounded shadow-lg hover:shadow-xl bg-white"
    v-on="$listeners"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "DashboardCard"
};
</script>
